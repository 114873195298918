body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    height: 100vh;
    width: 100%;
  }
  
  .circle {
    background-color: #d83b01;
    border-radius: 50%;
    color: #fff;
    height: 2em;
    position: relative;
    width: 2em;
  
    border: 1px solid transparent;
  }
  
  .circleText {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  
  .hover {
    background-color: #1e9ff2;
  }
  .marker {
    /* Set the marker size here */
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    /* Set the marker color here */
    background: #1e9ff2;

    display: inline-block;
    border-bottom-right-radius: 0;
    position: relative;
    transform: rotate(45deg);

    /* optional fanciness */
    border: 1px solid #881100;
}
.red {
    background: red !important;
}
.gray {
    background: gray !important;
    zoom: .75;
}
.yellow {
    background: yellow !important;
    zoom: .75;
}
.green {
    background: green !important;
}
/* inner circle (optional if you don't need the central dot) */
.marker::before {
    content: "";
    background: white;
    width: 50%;
    height: 50%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    /* optional fanciness */
    border: 1px solid #881100;
    box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.marker::after {
    content: "";
    background: rgba(128, 128, 128, 0.2);
    width: 75%;
    height: 75%;
    border-radius: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}
  